@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Roboto", sans-serif;
 --primary-color: #003d7e;
}


.ant-btn-default {
  display: none;
}

/* ====this is for nepali text editior====== */
@font-face{
  font-family: 'preeti';
  src: url('./fonts/Preeti\ Normal.otf') format('truetype') ;
}

.btn {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%), #004d8e;
}

.nav_item {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%);
}

.nav_link:hover {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%);
}


.nav_active {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%);
  color: white;
}

.inactive {
  background: #000;
}

/* .ant-tabs-ink-bar {
    background-color: transparent;
}   */

.sc-eDWCr {
  font-size: 16px;
  color: #5f6368;
  line-height: 18.75px;
  font-weight: 400;
}

/* sc-dkrFOg iSAVrt rdt_TableHeadRow */
/* hbotQl */
sc-gswNZR beUiVL rdt_TableHead .iSAVrt {
  background-color: red !important;
}

/* iSAVrt */

sc-lllmON gMliOo .jaZsat {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  background: red !important;
}

/* .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid #BF2D30 !important;
    z-index: 2;
  } */
/* 
  .ant-tabs-tab{
    border: none!important;
  } */

.rr {
  transform: rotate(90deg);
}

.sc-dkrFOg {
  background: #f9f9f9 !important;
}

.hh {
  fill: white;
}

.nav_link:hover svg path {
  fill: white;
}

.cc:hover svg path {
  stroke: white;
}

.cc:hover svg path {
  fill: white;
}

/* svg:hover path {
    fill: red;
} */

/* :where(.css-dev-only-do-not-override-sk7ap8) */
/* for selector button */
.ant-select-selector {
  /* border: none !important;
    border: 1px solid #C9C9C9 !important; */
  border-radius: 0 !important;
  height: 38px !important;
  /* height: 40px !important; 
    border-right: 0 !important;
    color: #505056 !important; */
}

.ant-select-selection-item {
  margin-top: 2px !important;
}

@media (min-width: 1366px) {
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 19px;
  }
}

.ant-input-password {
  border: 0px !important;
  background-color: #eeeeee !important;
  border-radius: 0px !important;
}

.ant-input {
  background-color: transparent !important;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  left: -114px !important;
}

.ant-drawer .ant-drawer-header-title {
  justify-content: end !important;
}

.ant-drawer .ant-drawer-header {
  border-bottom: 0px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-drawer .ant-drawer-header {
  padding: 25px 5px !important;
  /* display: none; */
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}




@keyframes myvariable {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(250px);
  }
}

.formskeleton{
  animation: myvariable 2s linear infinite;
}


@keyframes myvariable1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.formrowskeleton{
  animation: myvariable1 0.1s linear infinite;
}


.pagination {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  background-color: #f3f2f7;
  border-radius: 50px;
  /* padding :  5px; */
}

.pagination__button {
  background-color: #f3f2f7;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  /* overflow: hidden; */
  color: #333;
  cursor: pointer;
  /* padding: 5px;
  padding-left: 20px;
  padding-right: 20px; */
}

.pagination__button:hover {
  /* background-color: #F08B38; */
  color: #f08b38;
}

.activepagination {
  color: white;
  background-color: #f08b38;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50%;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}
.shadow{
      box-shadow: 0 4px 8px 0 rgba(34,41,47,.1);
}


/* Spinner */
.fullWindow-Spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  color: white;

  
}