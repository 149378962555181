/* Add general styling */
.App {
    text-align: center;
}

.dropzone {
    border: 2px dashed #cccccc;
    padding: 20px;
    cursor: pointer;
}

.previews {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.preview-box {
    border: 1px solid #cccccc;
    padding: 20px;
    width: 45%;
}

.preview-box h2 {
    margin-bottom: 10px;
}

/* Add specific styling for Excel preview */
.excel-preview-table {
    border-collapse: collapse;
    width: 100%;
}

.excel-preview-table th,
.excel-preview-table td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

.excel-preview-table th {
    background-color: #f2f2f2;
}

/* Add specific styling for Text preview */
.text-preview pre {
    white-space: pre-wrap;
    font-family: 'Courier New', monospace;
}